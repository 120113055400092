import React, { useEffect, useRef, useState } from 'react';
import { Sidebar, useTheme } from 'flowbite-react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FaUser } from 'react-icons/fa';
import { SidebarProfileDropdown } from './SidebarProfileDropdown';
import { SidebarprofileHeader } from './Sidebarprofileheader';
import { RenderSidebarList } from './RenderSidebarList';
import { Profile, RootState, sideNavItem } from '../../@types';
import { isMobile } from 'react-device-detect';
import {
  guestNavBarOrgLists,
  GUEST_PROFILE_CREATE_PATH,
  orgSidebarOptions,
  userSidebarOptions,
  userNavBarOrgLists,
  USER_PROFILE_DETAILS_PATH,
  ORG_PROFILE_DETAILS_PATH,
  SIGNUP_PATH,
  WALLET_CONNECT_WARNING_TEXT,
  WALLET_BUTTON_WARNING_TEXT,
} from '../../Utils/Constants';
import CommonButton from '../../Components/CommonButton';

import { fetchuserProfileId } from '../../Utils/Helpers';
import { useAccount } from 'wagmi';
import { useQuery } from '@apollo/client';
import { ALL_ORGANISATIONS } from '../../graphql/orgs';
import ConnectWallet from '../../Components/ConnectWallet';
import { ReactComponent as DarkLogo } from '../../assets/desights-logo-black.svg';
import { ReactComponent as LightLogo } from '../../assets/desights-logo-white.svg';
import CommonWarningModal from '../../Components/Modals/CommonWarningModal';
import smallLogo from '../../assets/desights-small-logo.png';
import smallLogoWhite from '../../assets/desight-small-logo-white.png';
const SideBar = ({ className }) => {
  const isOrgSelected = useSelector((state: RootState) => state.utils.isOrgSelected);
  const currentProfile = useSelector((state: RootState) => state.profile.currentProfile);
  const defaultProfile = useSelector((state: RootState) => state.profile.defaultProfile);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const { mode } = useTheme();

  const [organizationList, setAllOrganizationList] = useState([]);
  const { data: allOrg } = useQuery(ALL_ORGANISATIONS, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (allOrg?.fetchAllOrganisationsPage?.length > 0) {
      setAllOrganizationList(
        allOrg.fetchAllOrganisationsPage?.map((org) => {
          return {
            ...org,
            ...org?.profile,
            isOrganization: true,
            profileId: org?.id,
          };
        }),
      );
    }
  }, [allOrg]);

  const isGuest = useSelector((state: RootState) => state.utils.isGuest);
  const [currentUserProfiles, setCurrentUserProfiles] = useState<Profile[]>([]);
  const [enableProfileDropdown, setEnableProfileDropdown] = React.useState(false);
  const [renderSidebar, setRenderSidebar] = React.useState<sideNavItem[]>([]);
  const [showListselected, setShowListSelected] = React.useState<{
    login: boolean;
    key: number;
    imgSrc: string;
  }>({
    login: true,
    key: 0,
    imgSrc: 'https://m.media-amazon.com/images/I/51VOjZGpinL._UX569_.jpg',
  });
  const navigate = useNavigate();
  const { isConnected, address: userAddress } = useAccount();
  const [showCreateButton, setShowCreateButton] = useState(false);
  useEffect(() => {
    //
    const user = localStorage.getItem('selecteduser');
    if (!isGuest && user) {
      setShowListSelected({
        ...showListselected,
        key: parseInt(user),
        login: !parseInt(user),
      });
    }
  }, [isGuest]);

  useEffect(() => {
    if (isGuest) setRenderSidebar(guestNavBarOrgLists);
    else if (isOrgSelected)
      setRenderSidebar([
        {
          path: [ORG_PROFILE_DETAILS_PATH, currentProfile.profileId].join('/'),
          name: 'Profile',
          icon: FaUser,
        },
        ...orgSidebarOptions,
      ]);
    else
      setRenderSidebar([
        {
          path: [USER_PROFILE_DETAILS_PATH, defaultProfile.profileId].join('/'),
          name: 'My Profile',
          icon: FaUser,
        },
        ...userSidebarOptions,
      ]);
  }, [isOrgSelected, isGuest, defaultProfile.profileId, currentProfile.profileId]);

  useEffect(() => {
    if (defaultProfile.organizations.length) {
      setCurrentUserProfiles(
        organizationList.filter((org: Profile) => defaultProfile?.organizations?.includes(org.id)),
      );
    } else {
      setCurrentUserProfiles([]);
    }
  }, [defaultProfile, organizationList, currentProfile]);

  const showCreateButtonHandler = async () => {
    // TO SHOW CREATE PROFILE BUTTON IN SIDE BAR IF WALLET IS CONNECTED AND NO USERID IS FOUND
    const userProfileId = await fetchuserProfileId(userAddress);
    userProfileId ? setShowCreateButton(false) : setShowCreateButton(true);
  };

  useEffect(() => {
    isConnected && showCreateButtonHandler();
  }, [isConnected, userAddress]);

  // sidebar toggle
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const sidebarRef = useRef(null);

  useEffect(() => {
    //To detect  click outside of the Sidebar and close it if clicked anywhere outside of it.
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        if (sidebarOpen) {
          event.stopPropagation();
        }
        setSidebarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`h-full w-auto lg:w-64 ${className}`}>
      <button
        onClick={toggleSidebar}
        data-drawer-target='default-sidebar'
        data-drawer-toggle='default-sidebar'
        aria-controls='default-sidebar'
        type='button'
        className='sticky top-3 inline-flex items-start p-2 mt-2 ml-2.5 text-sm text-gray-500 rounded-lg lg:hidden bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:bg-gray-700 dark:focus:ring-gray-600'
      >
        <span className='sr-only'>Open sidebar</span>
        <svg
          className='w-6 h-6'
          aria-hidden='true'
          fill='currentColor'
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            clipRule='evenodd'
            fillRule='evenodd'
            d='M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z'
          ></path>
        </svg>
      </button>
      <CommonWarningModal
        buttonText={WALLET_BUTTON_WARNING_TEXT}
        show={showWarningModal}
        warningText={WALLET_CONNECT_WARNING_TEXT}
        onButtonClick={() => {
          setShowWarningModal(false);
        }}
      />
      <aside
        ref={sidebarRef}
        className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${
          sidebarOpen ? '' : '-translate-x-full lg:translate-x-0'
        }`}
        id='default-sidebar'
        aria-label='Sidenav'
      >
        <Sidebar aria-label='Sidebar with multi-level dropdown example'>
          <Sidebar.CTA className='!p-0'>
            <div className='flex justify-center items-center'>
              <div className='w-full'>
                {isGuest && location.pathname !== GUEST_PROFILE_CREATE_PATH ? (
                  <>
                    {showCreateButton && isConnected && (
                      <CommonButton
                        buttonText='Create My Account'
                        buttonClassName='w-full'
                        onClick={() => {
                          if (isMobile) {
                            setShowWarningModal(true);
                            return;
                          }
                          navigate(SIGNUP_PATH);
                        }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <SidebarprofileHeader
                      showListselected={showListselected}
                      currentUserOrg={currentUserProfiles}
                      setEnableProfileDropdown={setEnableProfileDropdown}
                      enableProfileDropdown={enableProfileDropdown}
                    />
                    {enableProfileDropdown && (
                      <SidebarProfileDropdown
                        ProfileUserData={[defaultProfile, ...currentUserProfiles]}
                        showListselected={showListselected}
                        setEnableProfileDropdown={setEnableProfileDropdown}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </Sidebar.CTA>
          {!isGuest && <hr className=' border-gray-200 dark:border-gray-700 my-2' />}
          <Sidebar.Items>
            <Sidebar.ItemGroup>
              {!isGuest && (
                <RenderSidebarList setSidebarOpen={setSidebarOpen} renderSidebar={renderSidebar} />
              )}
              {!isOrgSelected && (
                <>
                  <hr className='border-gray-200 sm:mx-auto dark:border-gray-700 my-2' />
                  <RenderSidebarList
                    setSidebarOpen={setSidebarOpen}
                    renderSidebar={isGuest ? guestNavBarOrgLists : userNavBarOrgLists}
                  />
                </>
              )}
              <hr className='border-gray-200 sm:mx-auto dark:border-gray-700 my-2' />
              {/* <RenderSidebarList renderSidebar={navBarFooterLists} />
                            <hr className="border-gray-200 sm:mx-auto dark:border-gray-700 my-2" /> */}
              <div className='flex justify-between items-center pt-1'>
                <div className='flex'>
                  {/* <IoColorPaletteOutline className='text-2xl text-gray-500 dark:text-gray-400 ml-2' /> */}
                  {/* <div className="dark:text-white pl-3 text-sm self-center">Theme</div> */}
                  <div className={`${isConnected ? 'ml-4' : ''}`}>
                    <ConnectWallet buttonText='Create My Profile' responsive={true} />
                  </div>
                </div>
                <div
                  className={`bg-white; dark:bg-gray-800 hover:bg-gray ${
                    !isConnected ? 'p-1 mt-1' : 'p-2 mr-3 mt-1'
                  } `}
                ></div>
              </div>
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </Sidebar>
        <div className='w-60 bg-white fixed text-center top-[92vh] pb-[38px] left-0 right-1  dark:bg-gray-800 py-2'>
          {/* Content of the item */}
          {mode === 'dark' ? (
            <DarkLogo
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
              className='desights-logo'
            />
          ) : (
            <LightLogo
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
              className='desights-logo bg-white'
            />
          )}
        </div>
      </aside>
      <div className='fixed left-[-6px] bottom-0 mb-3'>
        {mode === 'dark' ? (
          <img src={smallLogoWhite} alt='Desights' />
        ) : (
          <img src={smallLogo} alt='Desights' />
        )}
      </div>

      {/* Overlay so click on list doesnt work below sidebar*/}
      {sidebarOpen && <div className='fixed top-0 left-0 w-screen h-screen bg-transparent z-30' />}
    </div>
  );
};

export default SideBar;
