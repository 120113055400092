import { Button, Spinner } from 'flowbite-react';
import { CommonButtonProps } from '../@types';

export const colorObj = {
  failure: '!bg-red-600 dark:!bg-red-700 hover:!bg-red-700 dark:hover:!bg-red-800',
  warning: '!bg-yellow-400 dark:!bg-yellow-400 hover:!bg-yellow-500 dark:hover:!bg-yellow-500',
  info: '!bg-primary-500 dark:!bg-primary-700 hover:!bg-primary-600 dark:hover:!bg-primary-800',
  secondary:
    '!bg-secondary-500 dark:!bg-secondary-400 hover:!bg-secondary-500 dark:hover:!bg-secondary-500',
};

const CommonButton = ({
  showLoader = false,
  buttonText,
  ButtonIcon,
  buttonClassName,
  iconClassName,
  color = 'info',
  ...restProps
}: CommonButtonProps) => {
  const colorClass = colorObj[color as keyof typeof colorObj];
  return (
    <Button
      size='xs'
      className={`self-center ${colorClass ? colorClass : ''} ${
        buttonClassName ? buttonClassName : ''
      } ${showLoader ? `cursor-wait` : ''}`}
      {...restProps}
    >
      {showLoader && (
        <span className='mr-1 sm:mr-2'>
          <Spinner size='sm' light={true} />
        </span>
      )}
      {!showLoader && ButtonIcon && (
        <ButtonIcon className={`text-sm md:text-lg mr-1 sm:mr-2 ${iconClassName}`} />
      )}
      <span className={`min-w-[60px] md:min-w-[80px] ${buttonClassName}`}>{buttonText}</span>
    </Button>
  );
};

export default CommonButton;
