import { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Spinner } from 'flowbite-react';
import { Provider } from 'react-redux';
import store from './Store';
import { WagmiConfig } from 'wagmi';
import MainRouter from './Routes/MainRouter';
import { wagmiConfig } from './Utils/Helpers';
import NetworkSwitchListener from './Components/NetworkSwitchListener';
import TxnModal from './Components/Modals/TxnModal';
import { getMainDefinition } from '@apollo/client/utilities';
import ErrorBoundary from './Components/ErrorBoundary';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, split } from '@apollo/client';
import { primaryMetaTags, FBmetaOgTags, TwitterOgTags } from './Utils/Constants';
import MetaHemletTags from './Components/MetaHemletTags';
import CheckLatestFeatures from './Components/CheckLatestFeatures';
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API, // GraphQL server URL
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  httpLink,
  httpLink,
);

export const apolloClient = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        keyFields: false, // Disable default keyFields behavior
        merge: false, // Disable default merging behavior
      },
    },
    dataIdFromObject: () => null,
  }),
});

const App = () => {
  return (
    <>
      <MetaHemletTags
        pageTitle={primaryMetaTags.title}
        primaryDescription={primaryMetaTags.description}
        fbType={FBmetaOgTags.type}
        fbUrl={FBmetaOgTags.url}
        fbTitle={FBmetaOgTags.title}
        fbDescription={FBmetaOgTags.description}
        fbImage={FBmetaOgTags.image}
        twitterCard={TwitterOgTags.card}
        twitterURL={TwitterOgTags.url}
        twitterDescription={TwitterOgTags.description}
        twitterImage={TwitterOgTags.image}
        twitterTitle={TwitterOgTags.title}
      />
      <ApolloProvider client={apolloClient}>
        <Provider store={store}>
          <WagmiConfig config={wagmiConfig}>
            <Suspense
              fallback={
                <div className='text-center'>
                  <Spinner size='xl' />
                  test
                </div>
              }
            >
              <Router>
                <ErrorBoundary>
                  <MainRouter />
                  <NetworkSwitchListener />
                </ErrorBoundary>
              </Router>
            </Suspense>
            <TxnModal />
            <CheckLatestFeatures />
          </WagmiConfig>
        </Provider>
      </ApolloProvider>
      {/* <Web3Modal
        themeVariables={{
          '--w3m-font-family': 'Roboto, sans-serif',
          '--w3m-accent-color': '#0000ff',
        }}
        projectId={process.env.REACT_APP_WALLET_CONNECT_ID || ''}
        themeMode={mode}
        ethereumClient={ethereumClient}
        //defaultChain will ask user to switch network to supported chain when user try to connect wallet
        defaultChain={supportedChain[0]}
      /> */}
    </>
  );
};

export default App;
