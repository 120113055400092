import { Spinner, Tooltip } from 'flowbite-react';
import { BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, sidebarprofileHeaderInterface } from '../../@types';
import { Link } from 'react-router-dom';
import { AiOutlineSwap } from 'react-icons/ai';
import CommonAvatar from '../../Components/CommonAvatar';
import { useAccount } from 'wagmi';
import { formatAddress } from '../../Utils/Helpers';
import { updateCurrentProfileAction, updateSelectedProfileAction } from '../../Store/Actions';
import { AppDispatch } from '../../@types';
import { USER_SUBMISSIONS_LIST_PATH } from '../../Utils/Constants';
import StatDescription from '../../Components/StatDescription';

export const SidebarprofileHeader = ({
  setEnableProfileDropdown,
  enableProfileDropdown,
  currentUserOrg,
}: sidebarprofileHeaderInterface) => {
  const { name, profileImage } = useSelector((state: RootState) => state.profile.currentProfile);
  const { name: nameSync, image: imageSync } = useSelector(
    (state: RootState) => state?.utils?.sidebarSyncDetails,
  );
  const isGuest = useSelector((state: RootState) => state.utils.isGuest);
  const isOrgSelected = useSelector((state: RootState) => state.utils.isOrgSelected);
  const defaultProfile = useSelector((state: RootState) => state.profile.defaultProfile);
  const currentProfile = useSelector((state: RootState) => state.profile.currentProfile);
  const { address } = useAccount();
  const dispatch: AppDispatch = useDispatch();
  // const [profileName, setProfileName] = useState<string | WalletAddress>(address);

  return (
    <div>
      <CommonAvatar img={imageSync ? imageSync : profileImage} size='lg' navigation={false} />
      <div className={'flex w-full ' + (isGuest ? 'justify-center' : 'justify-between')}>
        <div className='ml-3 w-4/5'>
          <div className='text-center font-medium dark:text-white pt-2'>
            <div className='truncate'>
              {nameSync ? nameSync : name ? name : formatAddress(address)}
            </div>
          </div>
          <div className='flex justify-center pl-4'>
            <StatDescription
              text={formatAddress(currentProfile?.profileId)}
              showCopy={true}
              copyText={currentProfile?.profileId}
            />
          </div>
          {!isGuest && (
            <div className='text-center text-sm text-gray-500 dark:text-gray-400'>
              {isOrgSelected ? 'Organization Profile' : 'User Profile'}
            </div>
          )}
        </div>
        {!isGuest &&
          (!isOrgSelected ? (
            currentUserOrg.length < defaultProfile.organizations.length &&
            currentUserOrg.length < 1 ? (
              <div className='flex items-center pl-2'>
                <Spinner size='md' />
              </div>
            ) : (
              <div
                className='flex items-center pl-2 cursor-pointer'
                onClick={() => {
                  setEnableProfileDropdown(!enableProfileDropdown);
                }}
              >
                {enableProfileDropdown ? (
                  <BsFillCaretUpFill className='dark:text-white text-dark' size={20} />
                ) : (
                  <BsFillCaretDownFill className='dark:text-white text-dark' size={20} />
                )}
              </div>
            )
          ) : (
            <Link
              to={USER_SUBMISSIONS_LIST_PATH}
              className='flex'
              onClick={() => {
                dispatch(
                  updateSelectedProfileAction({
                    profileId: defaultProfile.profileId,
                    isOrg: false,
                  }),
                );
                dispatch(updateCurrentProfileAction(defaultProfile));
              }}
            >
              <div className='cursor-pointer self-center'>
                <Tooltip
                  content='Switch to User Profile'
                  trigger='hover'
                  placement='right'
                  className='whitespace-nowrap'
                >
                  <AiOutlineSwap size={20} className='dark:text-white text-dark' />
                </Tooltip>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};
