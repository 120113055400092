// import { EthereumClient, modalConnectors, walletConnectProvider } from "@web3modal/ethereum";
import { EthereumClient } from '@web3modal/ethereum';
import { signMessage, type SignMessageResult } from '@wagmi/core';

// import { chain, configureChains, createClient } from "wagmi";
import { polygon, polygonMumbai, mainnet, sepolia } from 'wagmi/chains';
import { Chain } from 'wagmi';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
//To add support for another chain just import from wagmi/chains and add in this array and add chain id in env
const projectId = process.env.REACT_APP_WALLET_CONNECT_ID || '';

const chainValue = parseInt(process.env.REACT_APP_CHAIN_ID) || 137;

const polygonAmoy: Chain = {
  id: 80002, // Replace with the actual chain ID
  name: 'Amoy',
  network: 'POLYGON AMOY TESTNET',
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18,
  },
  rpcUrls: {
    alchemy: {
      http: ['https://polygon-amoy.g.alchemy.com/v2/'],
      webSocket: ['wss://polygon-amoy.g.alchemy.com/v2/'],
    },
    infura: {
      http: ['https://polygon-amoy.infura.io/v3/'],
      webSocket: ['wss://polygon-amoy.infura.io/ws/v3/'],
    },
    default: {
      http: ['https://rpc.ankr.com/polygon_amoy'],
    },
    public: {
      http: ['https://rpc.ankr.com/polygon_amoy'],
    },
  },
  blockExplorers: {
    default: { name: 'PolygonScan', url: 'https://amoy.polygonscan.com/' }, // Replace with the actual block explorer URL
  },
  testnet: true, // Set to true if it's a testnet
};

const chains = [polygon, polygonMumbai, mainnet, sepolia, polygonAmoy];

export const supportedChain: Chain[] = chains.filter((chain) => {
  return chain.id == chainValue;
});

export const wagmiConfig = defaultWagmiConfig({ chains, projectId });

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeMode: 'dark',
  defaultChain: supportedChain[0],
  featuredWalletIds: ['c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96'],
  includeWalletIds: ['c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96'],
});
export const ethereumClient = new EthereumClient(wagmiConfig, supportedChain);

//Signature helper

export const signRandomMessage = async (message) => {
  const res: SignMessageResult = await signMessage({ message });
  console.log('signed res', res);
  return res;
};
