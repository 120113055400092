import ReactDOMClient from 'react-dom/client';
import { Flowbite } from 'flowbite-react';
import App from './App';
import './index.css';

try {
  const container = document.getElementById('root');
  if (!container) throw new Error('No root element was found in the document.');
  ReactDOMClient.createRoot(container).render(
    // <React.StrictMode>
    <Flowbite>
      <App />
    </Flowbite>,
    // </React.StrictMode>
  );
} catch (error) {
  console.error(error);
}
